import React, { useState } from "react"
// import { Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import {
  Container,
  VideoContainer,
  SingleVideoCotnainer,
} from "../../components/common/layout"
import VideoItems from "../../components/VideoItems/VideoItems"

import Modal from "../../components/Modal/Modal"

import { StaticQuery, graphql } from "gatsby"

const Videos = () => {
  const [showModal, setShowModal] = useState(false)
  const [video, setVideo] = useState(null)

  return (
    <StaticQuery
      query={graphql`
        query VideoQueryarchive2022 {
          allSanityVideo(filter: { year: { eq: "2022" } }) {
            edges {
              node {
                drive_link
                id
                youtube_iframe
                title
                age_group {
                  title
                }
                thumbnail {
                  asset {
                    url
                    source {
                      url
                    }
                  }
                }
              }
            }
          }
          allSanityAge(sort: { order: DESC, fields: order }) {
            edges {
              node {
                id
                title
              }
            }
          }
        }
      `}
      render={({ allSanityVideo, allSanityAge }) => (
        <Layout>
          <Seo title="Videos" />
          {showModal && (
            <Modal close={() => setShowModal(false)}>
              <VideoContainer>
                <SingleVideoCotnainer>
                  <iframe
                    width="560"
                    height="315"
                    src={video}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </SingleVideoCotnainer>
              </VideoContainer>
            </Modal>
          )}

          <Container>
            <div className="pt-12 pb-10 lg:pb-28">
              <div className="mx-auto">
                <div className="text-center mb-5">
                  <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                    "Feel The Force" 8th Annual Recital Videos
                  </h2>
                  <p className="my-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                    To download the full show click here:
                  </p>

                  <a
                    className="block md:inline-block bg-ascendance text-white rounded px-2 py-2 md:mr-2 mb-2"
                    href="https://vimeo.com/727009198"
                    target="_blank"
                    rel="noopener"
                  >
                    1:00PM Show
                  </a>
                  <a
                    className="block md:inline-block bg-ascendance text-white rounded px-2 py-2"
                    href="https://vimeo.com/727011530"
                    target="_blank"
                    rel="noopener"
                  >
                    6:00PM Show
                  </a>
                </div>
                <VideoItems
                  groups={allSanityAge.edges}
                  videoList={allSanityVideo.edges}
                  setShowModal={setShowModal}
                  setVideo={setVideo}
                  showPictures
                />
              </div>
            </div>
          </Container>
        </Layout>
      )}
    />
  )
}

export default Videos
